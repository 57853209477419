<template>
  <div class="page">
    <div class="header-container">
      <div class="back-item">
        <router-link class="content link" to="/requestreset">{{$t('Login.Forgot')}}</router-link>
      </div>
    </div>
    <div class="header-space"></div>
    <div>
      <label class="title">{{ $t('Login.Title') }}</label>
    </div>
    <div class="header-space"></div>
    <div class="login-form">
      <div>
        <TextBox :placeholder="$t('Login.Mail')" :type="'email'" :value="email" @input="email = $event"></TextBox>
      </div>
      <div>
        <TextBox :placeholder="$t('Login.Password')" :type="'password'" :value="password" @input="password = $event"></TextBox>
      </div>
      <div @click="login()">
        <Button :text="$t('Login.Login')"></Button>
      </div>
    </div>
  </div>
</template>

<script>
import TextBox from '../components/TextBox.vue'
import Button from '../components/Button.vue'

export default {
  name: "Start",
  components: {
    TextBox, Button
  },
  data() {
    return {
      email: "",
      password: "",
    };
  },
  methods: {
    login() {
      this.axios
        .post("auth/login", {
          email: this.email,
          password: this.password,
        })
        .then((res) => {
          if (res.status !== 200)
            return this.toast(
              "Es ist ein Fehler aufgetreten",
              "Ungültige Login-Daten",
              "danger"
            );
          localStorage.setItem("token", res.data.token);
          this.$store.state.loggedIn = true;
          this.$store.state.preName = res.data.preName;
          this.$store.state.lastName = res.data.lastName;
          this.$router.push("/");
        })
        .catch((/*err*/) => {
          this.toast(
            "Es ist ein Fehler aufgetreten",
            "Ungültige Login-Daten",
            "danger"
          );
        });
    },
  },
};
</script>

<style lang="scss">
@use '@/styles/colors';
@use '@/styles/config';

.login-form {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
</style>